<template>
  <div class="row">
    <div class="col-12">
      <c-table
        ref="grid"
        title="모든 작업계획별 소요자재"
        :columns="grid.columns"
        :merge="grid.merge"
        :data="grid.data"
        :gridHeight="grid.height"
        :editable="false"
        :hideBottom="true"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="false"
        :columnSetting="false"
      >
      </c-table>
    </div>
    <div class="col-12">
      <c-table
        ref="grid2"
        title="모든 작업계획별 서비스(외주)"
        :columns="grid2.columns"
        :merge="grid2.merge"
        :data="grid2.data"
        :gridHeight="grid2.height"
        :editable="false"
        :hideBottom="true"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="false"
        :columnSetting="false"
      >
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  props: {
    param: {
      type: Object,
      default: () => ({
        workPlanId: '',
        workPlanWorkId: '',
        equips: [],
        worksData: {},
      }),
    },
    click: {
      type: String,
      default: '',
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'workPlanWorkId' },
          { index: 1, colName: 'workPlanWorkId' },
          { index: 2, colName: 'workPlanWorkId' },
          { index: 3, colName: 'workPlanWorkId' },
        ],
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '항목번호',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'workPlanWorkName',
            field: 'workPlanWorkName',
            label: '작업명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'workOprTypeName',
            field: 'workOprTypeName',
            label: '작업제어구분',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workDtsStr',
            field: 'workDtsStr',
            label: '작업기간',
            align: 'center',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'materialNo',
            field: 'materialNo',
            label: '자재번호',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재',
            align: 'center',
            style: 'width: 300px',
            sortable: false,
          },
          {
            name: 'materialDesc',
            field: 'materialDesc',
            label: '용도',
            align: 'left',
            sortable: false,
          },
          {
            name: 'unitPrice',
            field: 'unitPrice',
            label: '단가',
            align: 'right',
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'unitEa',
            field: 'unitEa',
            label: '수량',
            align: 'right',
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'priceCost',
            field: 'priceCost',
            label: '소계',
            type: 'cost',
            style: 'width: 150px',
            sortable: false,
          },
        ],
        data: [],
        height: '320px',
      },
      grid2: {
        columns: [],
        merge: [
          { index: 0, colName: 'workPlanWorkId' },
          { index: 1, colName: 'workPlanWorkId' },
          { index: 2, colName: 'workPlanWorkId' },
          { index: 3, colName: 'workPlanWorkId' },
        ],
        data: [],
        height: '300px',
      },
      getUrl: '',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.$_.cloneDeep(this.param)
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    click() {
      this.getWorks();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.getUrl = selectConfig.wod.workorder.plan.work.allsubs.url;

      this.getColumn();
      this.getWorks();
    },
    getWorks() {
      if (this.param.workPlanId) {
        this.$http.url = this.getUrl;
        this.$http.param = {
          workPlanId: this.param.workPlanId,
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data.materialList;
          this.grid2.data = _result.data.serviceList;
        },);
      }
    },
    getColumn() {
      this.$comm.getComboItems('WO_COST_FACOTR_CD').then(_result => {
        this.grid2.columns = [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '항목번호',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'workPlanWorkName',
            field: 'workPlanWorkName',
            label: '작업명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'workOprTypeName',
            field: 'workOprTypeName',
            label: '작업제어구분',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workDtsStr',
            field: 'workDtsStr',
            label: '작업기간',
            align: 'center',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'workDt',
            field: 'workDt',
            label: '작업일',
            align: 'center',
            style: 'width: 140px',
            sortable: false,
          },
          {
            name: 'costFactorCd',
            field: 'costFactorCd',
            label: '원가요소',
            align: 'center',
            sortable: false,
            style: 'width: 200px',
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
          },
          {
            name: 'manCnt',
            field: 'manCnt',
            label: '작업인원',
            align: 'right',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'priceCost',
            field: 'priceCost',
            label: '인력단가',
            type: 'cost',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'manCost',
            field: 'manCost',
            label: '인건비',
            type: 'cost',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'materialCost',
            field: 'materialCost',
            label: '자재비',
            type: 'cost',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'sumCost',
            field: 'sumCost',
            label: '합계',
            type: 'cost',
            style: 'width: 120px',
            sortable: false,
          },
        ]
      });
    },
  }
};
</script>